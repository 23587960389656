/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import Image from "../components/Image";
import {graphql, Link} from 'gatsby'
import Layout from "../components/layout"
import {Grid} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Slider from "react-slick";
import "./relaqua-center.css";
import SEO from "../components/SEO";
import Map from '../components/MyMapComponent'
import Banner from "../components/Banner";


const sliderSettings = {
    dots: true,
    lazyLoad: true,
    infinite: true,
    swipeToSlide: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    fade: true
};

export default class RelaquaCenterTrainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            markers: this.props.data.allWordpressWpRelaquaCenter.edges.map((edge, i) => {
                return {
                    title: edge.node.title,
                    key: edge.node.title,
                    description: edge.node.acf.trainer_details.shortdescription,
                    link: `/relaquacenter/${edge.node.slug}`,
                    lat: parseFloat(edge.node.acf.maps.lat),
                    lng: parseFloat(edge.node.acf.maps.lng)
                }
            })
        };
    }

    render() {
        return <Layout id="relaqua-center">
            <SEO title={this.props.data.wordpressPage.yoast_meta.yoast_wpseo_title}
                 description={this.props.data.wordpressPage.yoast_meta.yoast_wpseo_metadesc}
            />

            <Slider {...sliderSettings}>
                {
                    this.props.data.wordpressPage.acf.slider_page.map((slide) => {
                        return (
                            slide.image && <Banner
                                customStyles={{marginTop: 0}}
                                title={slide.title}
                                link={slide.link}
                                linktext={slide.link_text}
                                description={slide.description}
                                image={slide.image}
                            />
                        );
                    })
                }
            </Slider>

            <div style={{margin: '2em'}}>{this.props.data.wordpressPage.acf.relaqua_center.description}</div>

            <Map containerStyles={{height: '80vh'}} markers={this.state.markers} />

            <div className='dark-hero-content'>
                <h2 className='title-big'>{this.props.data.wordpressPage.acf.relaqua_center.title}</h2>
            </div>

            <Grid container className='content' spacing={2}>
                {
                    this.props.data.allWordpressWpRelaquaCenter.edges.map((edge, i) =>
                        <Grid item lg={4} xs={12} style={{marginBottom: '2em'}} key={`trainer_${i}`} spacing={2}>
                            <Link to={`/relaquacenter/${edge.node.slug}`}>
                                <Card className="card">
                                    {edge.node.acf.trainer_details.image && edge.node.acf.trainer_details.image.localFile && <Image image={edge.node.acf.trainer_details.image} />}
                                    <CardContent style={{padding: '2em'}}>
                                        <h3 className='title-big'>
                                            {edge.node.title}
                                        </h3>
                                        {edge.node.acf.trainer_details.shortdescription}
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>)
                }
            </Grid>
        </Layout>
    }
}

export const pageQuery = graphql`
    query {
        allWordpressWpRelaquaCenter {
            edges {
                node {
                    id
                    slug
                    title
                    acf {
                        trainer_details {
                            shortdescription
                            image {
                               alt_text
title
description
localFile {
                                    childImageSharp {
                                        id
                                        fluid {
                                            base64
                                            tracedSVG
                                            aspectRatio
                                            src
                                            srcSet
                                            srcWebp
                                            srcSetWebp
                                            originalName
                                        }
                                    }
                                }
                            }
                        }
                        maps {
                            lat
                            lng
                        }
                    }
                }
            }
        }
        wordpressPage(slug: {eq: "relaqua-center-trainer"}) {
            yoast_meta {
                yoast_wpseo_title
                yoast_wpseo_metadesc
            }
            acf {
                relaqua_center {
                    description
                    title
                }
                contact {
                    title
                    description
                    image {
                       alt_text
title
description
localFile {
                            childImageSharp {
                                id
                                fluid {
                                    base64
                                    tracedSVG
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    originalName
                                }
                            }
                        }
                    }
                }
                slider_page {
                    title
                    description
                    link
                    link_text
                    image {
                       alt_text
title
description
localFile {
                            childImageSharp {
                                id
                                fluid {
                                    base64
                                    tracedSVG
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    originalName
                                }
                            }
                        }
                    }
                }
            }
        }
    }

`;


